import React, { useState, useEffect, useMemo } from "react"
import LocalTime from "local-time"
import httpClient from '../utils/httpClient'
import { Model as SurveyModel } from 'survey-core';
import { Survey } from 'survey-react-ui';
import "survey-core/i18n/catalan";
import "survey-core/i18n/spanish";
import 'survey-core/defaultV2.min.css';
import { PlainLightPanelless } from "survey-core/themes";

const SurveyComponent = ({ id, locale }) =>{
    const localStorageKey = useMemo(() => `pos-survey-${id}`, [id]);

    const [questions, setQuestions] = useState([])
    const [answers, setAnswers] = useState([])
    const [pageNo, setPageNo] = useState(0);

    useEffect(()=>{

      httpClient.get('/audits/' + id + '.json')
            .then( resp => {
                const dateTime = LocalTime.parseDate(resp.data.audit.description)
                const dateString = LocalTime.strftime(dateTime, "%d/%m/%Y %H:%M:%S")
                resp.data.audit.description = dateString

                setQuestions(resp.data.audit);
                setupPageSelector(resp.data.audit);
                setAnswers(resp.data.answers);

                var prevStatus = window.localStorage.getItem(localStorageKey) || "{}";
                const { savedAt, pageNo: page } = JSON.parse(prevStatus);

                if (savedAt && page && savedAt > resp.data.meta.savedAt) {
                  setPageNo(page);
                } else {
                  window.localStorage.removeItem(localStorageKey);
                }
            })
            .catch( resp => console.log(resp))
    }, [localStorageKey]);

    const setupPageSelector = (model) => {
        var selector = document.getElementById('pageSelector');
        selector.innerHTML = '';
        for (var i = 0; i < model.categories.length; i++) {
            var option = document.createElement("option");
            option.value = model.categories[i].page;
            option.text = model.categories[i].name;
            selector.add(option);
        }
    }

    const survey = new SurveyModel(questions);
    survey.applyTheme(PlainLightPanelless);

    // locale
    survey.locale = locale;
    survey.setLocalizableStringText("loadingSurvey", "Espereu, si us plau. L'enquesta s'està carregant ...");
    survey.setLocalizableStringText("pageNextText", "Guardar i següent");
    survey.setLocalizableStringText("completeText", "Completar");

    // config
    survey.currentPageNo        = Math.min(pageNo, survey.pageCount - 1);
    survey.data                 = answers;
    survey.sendResultOnPageNext = true;
    survey.showProgressBar      = 'off';

    // events
    survey.onPartialSend.add(async function (survey) {
      try {
        await httpClient.post('/audits/' + id  + '/save' , {"audit": survey.data});;

        const status = { pageNo: survey.currentPageNo, savedAt: Date.now() };
        window.localStorage.setItem(localStorageKey, JSON.stringify(status));
      } catch {
        alert("No s'han pogut guardar els canvis de la pàgina anterior")
      }
    });

    survey
        .onComplete
        .add(function (sender) {
          httpClient.post('/audits/' + id  + '/complete' , {"audit": sender.data})
                .then((_response) => {
                  window.localStorage.removeItem(localStorageKey);
                  window.location = `/audits/${id}/upload?recently_completed=true`
                })
        });

    survey
        .onCompleting
        .add(function(sender, options){
            if(survey.hasErrors()){
                alert("Hi ha preguntes pendents de respondre .");
                options.allowComplete=false;
            }
            else
                options.allowComplete=true;
        });

    survey
        .onAfterRenderQuestion
        .add(function(sender, options){
            document.getElementById('spinner').classList.add("hidden");
            document.getElementById('cpx-survey').classList.remove("hidden");
        });

    const onchangesel=(e) => {
        setPageNo(e.target.value);
    }

    return(
        <div className="h-full">
          <div id="cpx-survey" className="hidden h-full">
            <div className="px-3 mb-2">
              <select id="pageSelector" className="form-select appearance-none block w-full px-2 py-1 placeholder-gray-400 text-gray-700 focus:ring focus:outline-none"  onChange={onchangesel}  ></select>
            </div>
            <Survey
              model={survey}
            />
          </div>
          <div id="spinner" className="flex h-full  w-full grid place-content-center pb-20 gap-2 grid-cols-1  rounded-md p-2 text-lg text-center ">
            <div className="m-auto">
              <svg className="animate-spin h-32 w-32 text-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                {`<!--!Font Awesome Pro 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2025 Fonticons, Inc.-->`}
                <path fill="currentColor" d="M477.7 384c21.8-37.7 34.3-81.4 34.3-128C512 114.6 397.4 0 256 0l0 16c132.5 0 240 107.5 240 240c0 43.7-11.7 84.7-32.1 120l13.9 8z"/>
              </svg>
            </div>
            <div id="surveyState" className="justify-center">Carregant enquesta ...</div>
          </div>
        </div>
    )

}

export default SurveyComponent
